import React, { useState, useEffect } from "react";
import {Link, NavLink} from "react-router-dom";
import "./Navbar.css";
import logo from "../../images/logo.png";
import { menuLinks } from "./MenuLinks";
import { Button } from "../../components/button/Button";
import ServicesDropdown from "./ServicesDropdown";
import ApiRequest from "../../utils/ApiRequest";
import Cookies from 'universal-cookie';

function Navbar() {

  const cookies = new Cookies();

  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [shouldDropdown, setShouldDropdown] = useState(false);
  const [activeToken, setActiveToken] = useState(cookies.get('token'));
  const [loggedIn, setLoggedIn] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  // Check if the currently stored Token is valid, if it isn't reset browser to not logged in.
  const checkValidToken = async () => {
    await ApiRequest('http://localhost:8080/user/checktoken', 'POST', cookies.get('token'), {}).then((response) => {
      if (response.message != null) {
        setActiveToken(null);
        cookies.remove('token', { sameSite: true, secure: true, path: '/' });
      }
    });
  }

  // Check if there is a stored token cookie.
  // if(cookies.get('token')) {
  //   checkValidToken();
  // }

  // Update the loggedIn state when the activeToken value changes.
  useEffect(() => {
    if (activeToken) {
      setLoggedIn(true);
    }
    else {
      setLoggedIn(false);
    }
  }, [activeToken])


  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setShouldDropdown(false);
    } else {
      setShouldDropdown(true);
    }
  };

  const onMouseLeave = () => {
    setShouldDropdown(false);
  };

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true); // Set to true when log in is implemented
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <img src={logo} alt="Site Logo" />
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            {menuLinks.map((menu, index) => {
              return (
                <li
                    key={index}
                  className="nav-item"
                  onMouseEnter={menu.dropdown && onMouseEnter}
                  onMouseLeave={menu.dropdown && onMouseLeave}
                >
                  {" "}
                  {!menu.authed ? (
                    <NavLink
                      to={menu.link}
                      className={menu.className}
                      onClick={closeMobileMenu}
                    >
                      {menu.title}
                      {menu.dropdown}
                    </NavLink>
                  ) : menu.authed && loggedIn && (
                      <Link
                          to={menu.link}
                          className={menu.className}
                          onClick={closeMobileMenu}
                      >
                        {menu.title}
                        {menu.dropdown}
                      </Link>
                  )}
                  {shouldDropdown && menu.dropdown && (
                    <ServicesDropdown title={menu.title} />
                  )}
                </li>
              );
            })}
          </ul>
        </div>
        {/*{button && !loggedIn ? (*/}
        {/*    <form className={"form-inline"} name="login">*/}
        {/*      <label>*/}
        {/*        <input id="email" type="text" placeholder="E-Mail Address" disabled={true}/>*/}
        {/*      </label>*/}
        {/*      <label>*/}
        {/*        <input id="password" type="password" placeholder="Password" disabled={true}/>*/}
        {/*      </label>*/}
        {/*      <div>*/}
        {/*        <Button*/}
        {/*            buttonStyle={"btn--outline"}*/}
        {/*            onClick={async () => {*/}
        {/*              const form = document.forms["login"];*/}
        {/*              const email = form.elements["email"].value;*/}
        {/*              const password = form.elements["password"].value;*/}
        {/*                const request = await ApiRequest('http://localhost:8080/user/login', 'POST', null, {email, password});*/}
        {/*                if (request.token) {*/}
        {/*                  setShowErrorMessage(false);*/}
        {/*                  setActiveToken(request.token);*/}
        {/*                  cookies.set('token', request.token, { sameSite: 'None', secure: true, path: '/' });*/}
        {/*                  cookies.set('email', email, { sameSite: true, secure: true, path: '/' });*/}
        {/*                } else {*/}
        {/*                  setShowErrorMessage(true);*/}
        {/*                }*/}
        {/*            }}*/}
        {/*        >*/}
        {/*          Login*/}
        {/*        </Button>*/}
        {/*      </div>*/}
        {/*    </form>*/}
        {/*) : button && loggedIn && (<><h4 className={"h4"}>Welcome, Fenixico</h4>*/}
        {/*  <Button*/}
        {/*    buttonStyle={"btn--outline"}*/}
        {/*    onClick={() => {*/}
        {/*      setActiveToken(null);*/}
        {/*      cookies.remove('token', { sameSite: true, secure: true, path: '/' });*/}
        {/*    }}*/}
        {/*  >*/}
        {/*  Log Out*/}
        {/*</Button>*/}
        {/*  </>)}*/}
        {showErrorMessage && (<p className={"announcement"}>Login Failed: Please check your email address/password.</p>)}
      </nav>
    </>
  );
}

export default Navbar;
