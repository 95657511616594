import React from "react";
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import "./App.css";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import Home from "./pages/home/Home";
import TermsOfService from "./pages/terms-of-service/Terms-Of-Service";
import GameHosting from "./pages/game-hosting/GameHosting";
import WebHosting from "./pages/web-hosting/WebHosting"
import Membership from "./pages/membership/Membership";
import Contact from "./pages/contact/Contact";
import PageNotFound from "./pages/404/PageNotFound";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/game-hosting" element={<GameHosting />} />
          <Route path="/web-hosting" element={<WebHosting />} />
          <Route path="/membership" element={<Membership />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
