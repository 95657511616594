import React from "react";
import "../../App.css";
function TermsOfService() {
  return (
    <div className="hero-container">
      <div className="container-side-by-side">
        <h1>Terms of Service</h1>
      </div>
      <div className="box-content-terms">
        <h2 className="header-colour">1. Definitions</h2>
        <li className="list-style-boxed">
          1.1. Fenixico ('Us', 'We', 'He', 'My', 'Me', 'I', 'Fenixi.co') provides IT services
          ('services', 'server', 'membership) to the customer ('You', 'Your', 'Customer',
          'Client', 'User').
        </li>
      </div>
      <div className="box-content-terms">
        <h2 className="header-colour">2. Conditions</h2>
        <li className="list-style-boxed">
          2.1. By purchasing any of Fenixico's services you agree to the terms
          and conditions laid out in this document.
        </li>
        <li className="list-style-boxed">
          2.2. The Terms and Conditions / Privacy Policy is subject to change at
          any time. It is the responsibility of the client to check it regularly
          for updates.
        </li>
      </div>
      <div className="box-content-terms">
        <h2 className="header-colour">3. Payment</h2>
        <li className="list-style-boxed">
          3.1. All hosted service(s)/server(s) are paid for in advance. Failure
          to keep up monthly payments on your service can result in suspension
          and deletion of all of your files.
        </li>
        <li className="list-style-boxed">
          3.2. If a game server rental is somehow not right for you within 24 hours of
          purchasing, I can refund you. After the initial 24 hour period,
          refunds can be denied unless I deem it justified.
        </li>
        <li className="list-style-boxed">
          3.3. Cancellations must be requested BEFORE the payment due date. Please contact me to cancel a game server rental or Membership.
        </li>
        <li className="list-style-boxed">
          3.4. I retain no personal credit or debit card details.
        </li>
        <li className="list-style-boxed">
          3.5. Payment can be made via Bank Transfer, Standing Order or PayPal.
        </li>
      </div>
      <div className="box-content-terms">
        <h2 className="header-colour">4. Game Hosting</h2>
        <li className="list-style-boxed">
          4.1. The cost of a hosting subscription is subject to change at any
          time. At least one months notice will be given to you as the client.
        </li>
        <li className="list-style-boxed">
          4.2. All packages are monthly-rolling. You can stop the service at any
          time and you are not bound by a contract.
        </li>
        <li className="list-style-boxed">
          4.3. Although I do keep my own backups on dedicated storage, accidents can occur.
          I strongly recommend keeping local copies of all of your
          files/databases to minimise the likelihood of data loss. Fenixico
          cannot be held responsible for any data loss that may occur during
          your subscription.
        </li>
        <li className="list-style-boxed">
          4.4. In the event that your invoice is not paid, your files will be
          kept for up to a week after the payment due date. After this, they
          will be deleted.
        </li>
      </div>
      <div className="box-content-terms">
        <h2 className="header-colour">5. Web Hosting</h2>
        <li className="list-style-boxed">
          5.1. Web hosting services must abide by local and international law. Any copyright infringement, illegal materials not only including adult material, copyright infringement tools, DDoS tools and scripts, phishing sites, and data harvesters can be removed without notice, and suspension or termination is highly likely.
        </li>
        <li className="list-style-boxed">
          5.2. All of our web hosting services, including our unlimited plans, have fair usage policies applied. File servers are not allowed, and using our hosting as storage is also not allowed. The term 'unlimited' refers to critical web hosting files only, not stored files for personal or commercial use.
        </li>
        <li className="list-style-boxed">
          5.3. The cost of a hosting subscription is subject to change at any
          time. At least one months notice will be given to you as the client.
        </li>
        <li className="list-style-boxed">
          5.4. All packages are monthly-rolling. You can stop the service at any
          time and you are not bound by a contract.
        </li>
        <li className="list-style-boxed">
          5.5. Although I do keep my own backups on dedicated storage, accidents can occur.
          I strongly recommend keeping local copies of all of your
          files/databases to minimise the likelihood of data loss. Fenixico
          cannot be held responsible for any data loss that may occur during
          your subscription.
        </li>
        <li className="list-style-boxed">
          5.6. In the event that your invoice is not paid, your files will be
          kept for up to a week after the payment due date. After this, they
          will be deleted.
        </li>
      </div>
      <div className="box-content-terms">
        <h2 className="header-colour">6. Membership</h2>
        <li className="list-style-boxed">
          6.1. The cost of a Membership is subject to change at any
          time. At least one months notice will be given to you as the client.
        </li>
        <li className="list-style-boxed">
          6.2. Membership payments, unlike game server rentals, are non-refundable. No exceptions.
        </li>
        <li className="list-style-boxed">
          6.3. All Membership package payments are due on the 1st of each month.
          You will not receive a discount for payments made later in the month, and the price will not be discounted for shorter months such as February.
        </li>
        <li className="list-style-boxed">
          6.4. In the event that you do not maintain your Membership payments, you will be immediately removed from all Membership servers after the payment due date. Once payment is received, access will be restored.
        </li>
        <li className="list-style-boxed">
          6.5. Fenixico reserves the right to modify the Membership servers at any time. This includes the modification of server files and server rules.
        </li>
      </div>
      <div className="box-content-terms">
        <h2 className="header-colour">7. Prohibited Use</h2>
        <li className="list-style-boxed">
          7.1. Any unlawful activity carried out by you or a third party on your
          rented service can result in an immediate and non-refundable
          termination of services and in extreme cases legal action. This
          includes copyrighted materials, illicit pornography of children under
          the legal age, and illegal material or data of any kind.
        </li>
        <li className="list-style-boxed">
          7.2. The customer must defend and pay any damages, fees, judgments and
          costs incurred by Fenixico as a result of the customer breaching
          clause 6.1.
        </li>
        <li className="list-style-boxed">
          7.3. Fenixico reserves the right to refuse service to anybody, for any
          reason. Reasons for this could be abuse,
          libel, insults and unreasonable behaviour of any kind.
        </li>
      </div>
      <div className="box-content-terms">
        <h2 className="header-colour">8. Communication</h2>
        <li className="list-style-boxed">
          8.1. You may be contacted via email at any time regarding your
          service.
        </li>
        <li className="list-style-boxed">
          8.2. When you initially take out a service with us, you will be
          automatically added to my mailing list to be sent marketing emails.
          If you wish to opt out, please let me know by{" "}
          <a href="/contact">contacting me</a>.
        </li>
        <li className="list-style-boxed">
          8.3. Support is provided on a 'as soon as I can' basis. There is no
          SLA or expected response time. Please be patient and I will do my best
          to assist you.
        </li>
      </div>
      <div className="box-content-terms">
        <h2 className="header-colour">9. Privacy Policy</h2>
        <li className="list-style-boxed">
          9.1. We only hold necessary data in our client databases. Like your name, username,
          email address and hashed password for our own use and do not
          share data with any third-parties.
        </li>
        <li className="list-style-boxed">
          9.2. Your data may be stored on third-party hardware which is rented
          professionally by us.
        </li>
        <li className="list-style-boxed">
          9.3. Naturally, we store all other data that you choose to store with us, such as game server files and website files.
        </li>
      </div>
    </div>
  );
}

export default TermsOfService;
