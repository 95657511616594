import React from "react";
import "../../App.css";

import BlockIcon from '@mui/icons-material/Block';

function Contact() {
  return (
    <div className="hero-container">
      <div className="container-side-by-side">
        <div className="text-side-by-side">
        <BlockIcon sx={{ fontSize: 500 }} />
        </div>
      </div>
        <div className="container-side-by-side">
            <div className="text-side-by-side">
                <h1>Oops! That page does not exist.</h1>
            </div>
        </div>
    </div>
  );
}

export default Contact;
