import React from "react";
import "../../App.css";
import { Button } from "../../components/button/Button";
import {Link} from "react-router-dom";
function WebHosting() {
  return (
    <div className="hero-container">
      <div className="container-side-by-side">
        <h1>Web Hosting</h1>
      </div>
      <div className="container-side-by-side">
        <p>
          {" "}
          I have a variety of Web Hosting packages available for purchase. Take
          a look, and if you have any questions please don't hesitate to ask!<br></br><br></br>
          By purchasing Web Hosting, you are agreeing to the <Link to="/terms-of-service">Terms of Service</Link>.{" "}
        </p>
      </div>
      <div className="container-side-by-side">
        <div className="box-content-pricing">
          <h1 className="gradient-colour">Already a Customer?</h1>
          <li className="list-style-unboxed">
            <p>Access the Web Hosting Panel</p><br></br>
            <Button
                buttonStyle={"btn--outline"}
                onClick={() => {
                  window.open("https://wp.fenixi.co:8080");
                }}
            >
              <u>Web Panel</u>
            </Button>
          </li>
        </div>
        <div className="box-content-pricing">
          <h1 className="gradient-colour">Ready to buy?</h1>
          <li className="list-style-unboxed">
            <p>Get in touch!</p><br></br>
            <Button
                buttonStyle={"btn--outline"}
                onClick={() => {
                  window.location.href = 'https://fenixi.co/contact';
                }}
            >
              <u>Contact</u>
            </Button>
          </li>
        </div>
      </div>
      <div className="container-side-by-side">
        <div className="box-content-pricing">
          <h1 className="bronze-colour">Bronze</h1>
          <h2 className="purple-colour">
            <u>£5.00</u> per month
          </h2>
          <li className="list-style-unboxed">
            Disk Space...........................2GB
          </li>
          <li className="list-style-unboxed">
            Bandwidth.........................10GB
          </li>
          <li className="list-style-unboxed">
            Databases.................................0
          </li>
          <li className="list-style-unboxed">
            Email Accounts.........................0
          </li>
          <li className="list-style-unboxed">
            Let's Encrypt SSL Certificate ✅
          </li>
        </div>
        <div className="box-content-pricing">
          <h1 className="silver-colour">Silver</h1>
          <h2 className="purple-colour">
            <u>£7.50</u> per month
          </h2>
          <li className="list-style-unboxed">
            Disk Space..........................10GB
          </li>
          <li className="list-style-unboxed">
            Bandwidth........................100GB
          </li>
          <li className="list-style-unboxed">
            Databases..................................1
          </li>
          <li className="list-style-unboxed">
            Email Accounts..........................2
          </li>
          <li className="list-style-unboxed">
            Let's Encrypt SSL Certificate ✅
          </li>
        </div>
        <div className="box-content-pricing">
          <h1 className="gold-colour">Gold</h1>
          <h2 className="purple-colour">
            <u>£10.00</u> per month
          </h2>
          <li className="list-style-unboxed">
            Disk Space..........................100GB
          </li>
          <li className="list-style-unboxed">
            Bandwidth.....................Unlimited
          </li>
          <li className="list-style-unboxed">
            Databases...................................5
          </li>
          <li className="list-style-unboxed">
            Email Accounts...........................5
          </li>
          <li className="list-style-unboxed">
            Let's Encrypt SSL Certificate ✅
          </li>
        </div>
        {/*<div className="box-content-pricing">*/}
        {/*  <h1 className="red-colour">Ruby</h1>*/}
        {/*  <h2 className="purple-colour">*/}
        {/*    <u>££</u> per month*/}
        {/*  </h2>*/}
        {/*  <li className="list-style-unboxed">*/}
        {/*    Tailored for your requirements........✅*/}
        {/*  </li>*/}
        {/*  <li className="list-style-unboxed">*/}
        {/*    Website is maintained for you..........✅*/}
        {/*  </li>*/}
        {/*  <li className="list-style-unboxed">*/}
        {/*    Priority Support.................................✅*/}
        {/*  </li>*/}
        {/*  <li className="list-style-unboxed">*/}
        {/*    NodeJS Ready.....................................✅*/}
        {/*  </li>*/}
        {/*  <li className="list-style-unboxed">*/}
        {/*    Enquire to see how I can help you!*/}
        {/*  </li>*/}
        {/*</div>*/}
      </div>
    </div>
  );
}

export default WebHosting;
