import React from "react";
import "../../App.css";
import { Button } from "../../components/button/Button";
import { SliderComponent } from "../../components/slider/Slider";
import {Link} from "react-router-dom";

function GameHosting() {

  return (
    <div className="hero-container">
      <div className="container-side-by-side">
        <h1>Game Hosting</h1>
      </div>
      <div className="container-side-by-side">
        <p>
          {" "}
          Rent your own powerful game server, and have full
          control via my Pterodactyl Panel instance.<br></br><br></br>
          By renting a server, you are agreeing to the <Link to="/terms-of-service">Terms of Service</Link>.
          {" "}
        </p>
      </div>
      <div className="container-side-by-side">
        <div className="box-content-pricing">
          <h1 className="gradient-colour">Already a Customer?</h1>
          <li className="list-style-unboxed">
            <p>Access the Game Hosting Panel</p><br></br>
            <Button
                buttonStyle={"btn--outline"}
                onClick={() => {
                  window.open("https://gp.fenixi.co/");
                }}
            >
              <u>Game Panel</u>
            </Button>
          </li>
        </div>
        <div className="box-content-pricing">
          <h1 className="gradient-colour">Ready to buy?</h1>
          <li className="list-style-unboxed">
            <p>Get in touch!</p><br></br>
            <Button
                buttonStyle={"btn--outline"}
                onClick={() => {
                  window.location.href = 'https://fenixi.co/contact';
                }}
            >
              <u>Contact</u>
            </Button>
          </li>
        </div>
      </div>
      <div className="container-side-by-side">
        <div className="box-content-pricing">
          <h1 className="ark-background">ARK Survival Evolved</h1>
          <h2 className="bronze-colour">
            From <u>£10.00</u> per month
          </h2>
            {SliderComponent(
                '8,50,10,10.00,' +
                '8,50,20,14.00,' +
                '12,50,40,24.00,' +
                '14,50,50,30.00,' +
                '16,100,100,45.00')}
        </div>
        <div className="box-content-pricing">
          <h1 className="csgo-background">Counter-Strike 2</h1>
          <h2 className="bronze-colour">
            From <u>£4.00</u> per month
          </h2>
          {SliderComponent(
              '5,20,12,4.00,' +
              '5,20,24,8.00,' +
              '8,20,32,14.00')}
        </div>
        <div className="box-content-pricing">
          <h1 className="gmod-background">Garry's Mod</h1>
          <h2 className="bronze-colour">
            From <u>£5.00</u> per month
          </h2>
            {SliderComponent(
                '5,20,10,5.00,' +
                '5,20,25,10.00,' +
                '8,20,50,25.00,' +
                '12,20,100,40.00')}
        </div>
        <div className="box-content-pricing">
          <h1 className="minecraft-background">Minecraft (Java)</h1>
          <h2 className="bronze-colour">
            From <u>£8.00</u> per month
          </h2>
            {SliderComponent(
                '3,20,Unlimited,8.00,' +
                '4,20,Unlimited,10.00,' +
                '6,20,Unlimited,12.50,' +
                '8,20,Unlimited,16.50,' +
                '12,20,Unlimited,20.00')}
        </div>
        <div className="box-content-pricing">
          <h1 className="rust-background">Rust</h1>
          <h2 className="bronze-colour">
            From <u>£10.00</u> per month
          </h2>
            {SliderComponent(
                '10,20,50,10.00,' +
                '12,20,100,20.00,' +
                '16,20,150,40.00,' +
                '16,20,200,50.00')}
        </div>
        <div className="box-content-pricing">
          <h1 className="stormworks-background">Stormworks</h1>
          <h2 className="bronze-colour">
            From <u>£5.00</u> per month
          </h2>
          {SliderComponent(
              '5,20,10,5.00,' +
              '8,20,20,12.00,' +
              '12,20,30,20.00')}
        </div>
        <div className="box-content-pricing">
          <h1 className="tf2-background">Team Fortress 2</h1>
          <h2 className="bronze-colour">
            From <u>£5.00</u> per month
          </h2>
          {SliderComponent(
              '5,20,12,5.00,' +
              '5,20,24,10.00,' +
              '8,20,32,18.00')}
        </div>
        <div className="box-content-pricing">
          <h1 className="terraria-background">Terraria</h1>
          <h2 className="bronze-colour">
            From <u>£5.00</u> per month
          </h2>
            {SliderComponent(
                '5,10,10,5.00,' +
                '5,10,20,10.00,' +
                '8,10,30,20.00,' +
                '8,10,60,30.00')}
        </div>
        <div className="box-content-pricing">
          <h1 className="theforest-background">The Forest</h1>
          <h2 className="bronze-colour">
            From <u>£6.00</u> per month
          </h2>
          {SliderComponent(
              '5,20,10,6.00,' +
              '8,20,20,14.00,' +
              '8,20,30,20.00')}
        </div>
        <div className="box-content-pricing">
          <h1 className="valheim-background">Valheim</h1>
          <h2 className="bronze-colour">
            From <u>£8.00</u> per month
          </h2>
          {SliderComponent(
              '5,20,16,8.00,' +
              '8,20,32,16.00,' +
              '12,20,64,32.00')}
        </div>
      </div>
    </div>
  );
}

export default GameHosting;
