import React, { useState } from "react";
import "./ServicesDropdown.css";
import { Link } from "react-router-dom";

import { servicesSubLinks } from "./ServicesSubLinks";

function ServicesDropdown(props) {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  let subMenuToUse = undefined;

  function determineDropdownItems() {
    if (props.title === "Services 🠿") {
      subMenuToUse = servicesSubLinks;
    } else {
      // Nothing yet
    }
  }

  determineDropdownItems();

  return (
    <>
      <ul
        onClick={handleClick}
        className={click ? "dropdown-menu clicked" : "dropdown-menu"}
      >
        {subMenuToUse.map((item, index) => {
          return (
            <li key={index}>
              {props.title === item.parent && (
                <Link className="dropdown-link" to={item.link}>
                  {item.label}
                </Link>
              )}
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default ServicesDropdown;
