import {Box} from "@mui/material";
import {Slider} from "@mui/material";
import "../../App.css";
import React, {useState} from "react";

export const SliderComponent = (data) => {

    const splitData = data.split(",");

    const [ram, setRam] = useState(splitData[0]);
    const [diskSpace, setDiskSpace] = useState(splitData[1]);
    const [playerSlots, setPlayerSlots] = useState(splitData[2]);
    const [price, setPrice] = useState(splitData[3]);

    const totalNumberOfTiers = splitData.length / 4;

    const marks = () => {

        const completed = []
        const tierDivider = 100 / (totalNumberOfTiers - 1);
        let tierCounter = tierDivider;

        for (let i = 0; i < totalNumberOfTiers; i +=1){
            if (completed.length === 0) {
                completed.push(
                    {
                        value: 0,
                        label: `Tier 1`,
                    },
                )
            }
            else {
            completed.push(
                {
                    value: tierCounter,
                    label: `Tier ${i+1}`,
                },
            )
                tierCounter = tierCounter + tierDivider;
            }
        }
        return completed;
    }

    function handleSliderChange(props) {

        if (props.target.value === 0) {
            setRam(splitData[0])
            setDiskSpace(splitData[1])
            setPlayerSlots(splitData[2])
            setPrice(splitData[3])
        }
        else {
            const selectedTierNumber = Math.ceil(props.target.value / (100 / totalNumberOfTiers) - 1)
            setRam(splitData[(4 * selectedTierNumber)])
            setDiskSpace(splitData[(4 * selectedTierNumber) + 1])
            setPlayerSlots(splitData[(4 * selectedTierNumber) + 2])
            setPrice(splitData[(4 * selectedTierNumber) + 3])
        }



    }

    function valuetext(value) {
        return `${value}`;
    }

    return (
        <>
                <li className="list-style-unboxed">
            <center>
                <Box sx={{width: 200}}>
                    <Slider
                        aria-label="Always visible"
                        defaultValue={0}
                        getAriaValueText={valuetext}
                        step={null}
                        marks={marks()}
                        valueLabelDisplay="off"
                        onChange={handleSliderChange}
                        sx={{
                            color: 'white',
                            '& .MuiSlider-markLabel': {
                                color: 'white',
                            },
                        }}/>
                </Box>

                <table>
                    <thead>
                        <tr>
                            <th>RAM:</th>
                            <td>{ram}GB</td>
                        </tr>
                    </thead>
                    <thead>
                        <tr>
                            <th>Disk Space:</th>
                            <td>{diskSpace}GB</td>
                        </tr>
                    </thead>
                    <thead>
                        <tr>
                            <th>Player Slots:</th>
                            <td>{playerSlots}</td>
                        </tr>
                    </thead>
                    <thead>
                        <tr>
                            <th>Price per Month:</th>
                            <td>£{price}</td>
                        </tr>
                    </thead>
                </table>
            </center>
                </li>
        </>
)
}