export const menuLinks = [
  // Normal Links
  {
    title: "Home",
    link: "/",
    className: "nav-links",
  },
  {
    title: "Services 🠿",
    link: "#",
    className: "nav-links-no-active",
    dropdown: true,
  },
  // {
  //   title: "Game Hosting",
  //   link: "/game-hosting",
  //   className: "nav-links",
  // },
  // {
  //   title: "Web Hosting",
  //   link: "/web-hosting",
  //   className: "nav-links",
  // },
  {
    title: "Membership",
    link: "/membership",
    className: "nav-links",
  },
  {
    title: "Contact",
    link: "/contact",
    className: "nav-links",
  },

  {
    title: "Home",
    link: "/",
    className: "nav-links-mobile",
  },
  {
    title: "Game Hosting",
    link: "/game-hosting",
    className: "nav-links-mobile",
  },
  {
    title: "Web Hosting",
    link: "/web-hosting",
    className: "nav-links-mobile",
  },
  {
    title: "Membership",
    link: "/membership",
    className: "nav-links-mobile",
  },
  {
    title: "Contact",
    link: "/contact",
    className: "nav-links-mobile",
  },
];
