import React from "react";
import "../../App.css";
import avatar from "../../images/animated-fenixico.png";

import CodeIcon from "@mui/icons-material/Code";
import GamepadIcon from "@mui/icons-material/Gamepad";
import SchoolIcon from "@mui/icons-material/School";
import StorageIcon from "@mui/icons-material/Storage";

function Home() {
  return (
    <div className="hero-container">
      <div className="container-side-by-side">
        <img src={avatar} alt="Fenixico's Avatar" />
        <div className="text-side-by-side">
          <h1>Hey there!</h1>
          <p>I'm Fenixico.</p>
          <p>I'm a Software Engineer and technology enthusiast.</p>
        </div>
      </div>
      <div className="container-side-by-side">
        <div className="icon-box">
          <CodeIcon sx={{ fontSize: 200 }} />
          <h1>Development</h1>
        </div>
        <div className="icon-box">
          <SchoolIcon sx={{ fontSize: 200 }} />
          <h1>Tutorials</h1>
        </div>
        <div className="icon-box">
          <StorageIcon sx={{ fontSize: 200 }} />
          <h1>Web Hosting</h1>
        </div>
        <div className="icon-box">
          <GamepadIcon sx={{ fontSize: 200 }} />
          <h1>Game Hosting</h1>
        </div>
      </div>
    </div>
  );
}

export default Home;
