import { Link } from "react-router-dom";
import "./Footer.css";

function currentYear() {
  return new Date().getFullYear();
}

function Footer() {
  return (
    <>
      <div className="footer">
        <div className="footer-maintext">
          <p>Copyright © {currentYear()} Fenixico</p>
        </div>
        <div className="footer-link">
          <Link to="/terms-of-service">Terms of Service</Link> |{" "}
          <Link to="/terms-of-service">Privacy Policy</Link>
        </div>
      </div>
    </>
  );
}

export default Footer;
