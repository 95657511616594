import React from "react";
import "../../App.css";
import { Link } from "react-router-dom";

function Membership() {
  return (
    <div className="hero-container">
      <div className="container-side-by-side">
        <h1>Membership</h1>
      </div>
      <div className="box-content-terms">
        <h2 className="header-colour">❓ What is a Membership?</h2>
        <li className="list-style-unboxed">
          A Membership gives you access to a collection of private game servers,
          running 24/7 on powerful dedicated hardware.
        </li>
      </div>
      <div className="box-content-terms">
        <h2 className="header-colour">🪙 Is there a cost?</h2>
        <li className="list-style-unboxed">
            🔵 There is no fixed cost for joining these game servers, but donations towards hosting costs would be greatly appreciated.{" "}
          <br></br>
          <br></br>
            🔵 Donations can be made via a Bank Transfer or PayPal.
          <br></br>
        </li>
      </div>
      <div className="box-content-terms">
        <h2 className="header-colour">📝 How do I join?</h2>
            <li className="list-style-unboxed">
              As these are private game servers, they are restricted to friends or
              friends-of-friends only. Please message me directly on Discord if you wish to join.
            </li>
      </div>
      <p></p>
      <div className="box-content-membership">
        <h2 className="red-colour">🔒 Private Servers</h2>
      </div>
      <div className="container-side-by-side">
          <div className="box-content-pricing">
              <h1 className="minecraft-background">
                  Fenixicraft <br></br>(Vanilla Minecraft)
              </h1>
              <h2 className="header-colour">
                  <u>vmc.fenixi.co</u>
              </h2>
          </div>
        <div className="box-content-pricing">
          <h1 className="minecraft-background">
            All the Mods 9 <br></br>(Modded Minecraft)
          </h1>
          <h2 className="header-colour">
            <u>atm9.fenixi.co</u>
          </h2>
        </div>
          <div className="box-content-pricing">
              <h1 className="gta-background">
                  GTA V <br></br>(FiveM)
              </h1>
              <h2 className="header-colour">
                  <u>gta.fenixi.co</u>
              </h2>
          </div>
      </div>
      <center>
        <li className="list-style-unboxed">
          🔗
          <Link
            to={"#"}
            onClick={() => {
              window.open(
                "https://www.technicpack.net/modpack/fenixicos-atm9.1984432"
              );
            }}
          >
            All The Mods 9
          </Link>
        </li>
      </center>
    </div>
  );
}

export default Membership;
