export const servicesSubLinks = [
  {
    parent: "Services 🠿",
    label: "Game Hosting",
    link: "/game-hosting",
  },
    {
        parent: "Services 🠿",
        label: "Web Hosting",
        link: "/web-hosting",
    },
];
