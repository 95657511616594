async function ApiRequest(url, method, token, body) {
    try {
        const request = await fetch(url, {
            method: method,
            headers: checkHeaderUsage(token),
            body: JSON.stringify(body)
        });
        return await request.json();
    }
    catch (ex) {
        console.log(ex);
        throw new Error('Error connecting to API')
    }
}

function checkHeaderUsage(token) {
    if (token == null) {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }
    else {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }
}

export default ApiRequest;