import React from "react";
import "../../App.css";

import EmailIcon from '@mui/icons-material/Email';

function Contact() {
  return (
    <div className="hero-container">
      <div className="container-side-by-side">
        <h1>Contact</h1>
      </div>
      <div className="container-side-by-side">
        <div className="text-side-by-side">
        <EmailIcon sx={{ fontSize: 400 }} />
        </div>
        <div className="text-side-by-side">
          <h1>Get in touch!</h1>
          <p>Discord: fenixico</p>
          <p>E-Mail: contact@fenixi.co</p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
